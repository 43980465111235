import { type LoaderFunctionArgs } from '@remix-run/node'
import { json, Outlet, useLoaderData, useNavigate } from "@remix-run/react"
import { ArrowRight, Home } from 'lucide-react'
// import { type ReactNode } from 'react'
import { AppLogo } from "#app/components/ui/app/app-logo.tsx"
import { Button } from "#app/components/ui/catalyst/button.tsx"
import { getUserId } from "#app/utils/auth.server.ts"

export const loader = async ({ request }: LoaderFunctionArgs) => {
    const userId = await getUserId(request)
    return json({ userId })
}

export default function MarketingLayout() {
    const { userId } = useLoaderData<typeof loader>()
    return (
        <div className="flex grow flex-col gap-y-5 overflow-y-auto">
            <div className="flex h-16 items-center">
                <div className='w-full flex items-center justify-between px-6'>
                    <AppLogo />
                    <CTAButton isLoggedIn={userId !== null && userId !== ''} />
                </div>
                {/* <div className="hidden sm:flex gap-x-8 absolute w-full justify-center pointer-events-none">
                    <NavLink to={'/#features'}>Features</NavLink>
                    <NavLink to={'/#pricing'}>Pricing</NavLink>
                    <NavLink to={'/blogs'}>Blog</NavLink>
                </div> */}
            </div>
            <Outlet />
        </div>
    )
}

// const NavLink = ({ to, children }: { to: string, children: ReactNode }) => {
//     return (
//         <Link to={to} className='text-base underline-offset-4 hover:underline pointer-events-auto'>
//             {children}
//         </Link>
//     )
// }

const CTAButton = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
    const navigate = useNavigate()
    return (
        <Button color='orange' onClick={() => navigate('/h')} className='!md:text-sm'>
            <div className='flex items-center gap-x-2'>
                {isLoggedIn
                    ? (
                        <>
                            <Home size={16} /> Home
                        </>
                    )
                    : (
                        <>
                            Sign up for free
                        </>
                    )
                }
                {isLoggedIn && (
                    <ArrowRight size={16} />
                )}
            </div>
        </Button>
    )
}
